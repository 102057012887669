import React from "react";
import "../../../Assets/css/tokyoHome.css";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import DiamondImg from "../../../Containers/Front/tokyo/images/diamond.jpg";
import engImg from "../../../Containers/Front/tokyo/images/engagement_ring.jpg";
import weddingImg from "../../../Containers/Front/tokyo/images/wedding_ring.jpg";
import Slider from "react-slick";
import { isMobileOnly} from 'react-device-detect'

const EngDiaWeddingBanner = () => {
  const history = useHistory();
  const engWedDiamondData = [
    {
      img: engImg,
      name: "Engagement Rings",
      url: "/ringsettings",
    },
    {
      img: DiamondImg,
      name: "Diamonds",
      url: "/diamonds",
    },
    {
      img: weddingImg,
      name: "Wedding Rings",
      url: "/jewelry/wedding-rings",
    },
  ];
  var settingsEng = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="t_EngDiaWeddingBanner_section">
      <Container className="tokyo_custome_container">
       

        {/* mobile view */}
    {isMobileOnly ?
    <Row>
    <Col sm={12} md={12} lg={12} className="p-0">
      <Slider {...settingsEng}>
        {engWedDiamondData.map((val) => (
          <div id="banner0" className="banners explore_new"  onClick={()=>history.push(val.url)}>
            <div
              className="banner-block banner-1"
            >
              <div className="banner-box bimg_box">
                <img
                  src={val.img}
                  alt="banner-1"
                  className="img-responsive"
                />

                <div className="s-desc">
                  <h3>{val.name}</h3>
                  <div className="bannerb_button text-center">
                    <NavLink className="main_button" to={val.url}>
                      Shop Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Col>
  </Row>
    : 
    <div id="banner0" className="banners explore_new">
    <div className="row mr0">
      <div className="col-md-4 col-sm-4 col-12 pd0">
        <div
          className="banner-block banner-1"
          onClick={()=>history.push("/ringsettings")}
        >
          <div className="banner-box bimg_box">
            <img src={engImg} alt="banner-1" className="img-responsive" />

            <div className="s-desc">
              <h3>Engagement Rings</h3>
              <div className="bannerb_button text-center">
                <NavLink className="main_button" to="/ringsettings">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-sm-4 col-12 pd0">
        <div
          className="banner-block banner-1"
          onClick={()=>history.push("/diamonds")}
        >
          <div className="banner-box bimg_box">
            <img
              src={DiamondImg}
              alt="banner-1"
              className="img-responsive"
            />

            <div className="s-desc">
              <h3>Diamonds</h3>
              <div className="bannerb_button">
                <NavLink className="main_button" to="/diamonds">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-sm-4 col-12 pd0">
        <div
          className="banner-block banner-1"
          onClick={()=>history.push("/jewelry/wedding-rings")}
        >
          <div className="banner-box bimg_box">
            <img
              src={weddingImg}
              alt="banner-1"
              className="img-responsive"
            />

            <div className="s-desc">
              <h3>Wedding Rings</h3>
              <div className="bannerb_button">
                <NavLink className="main_button" to="/jewelry/wedding-rings">
                  Shop Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    }    
      </Container>
    </div>
  );
};

export default EngDiaWeddingBanner;
