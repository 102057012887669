import React from 'react';
import { Container, NavLink, Row, Col } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';
import google_icon from "../../Assets/images/google-reviews.jpg";
// import rcs_quote_new from "../../Assets/images/home/quote.png";
import yelp_icon from "../../Assets/images/yelp-reviews.jpg";
import ivouch_icon from "../../Assets/images/ivouch-reviews.jpg";


import dummy_user from "../../Assets/images/home/dummy_user.png";
import { useHistory } from 'react-router-dom';
import { client_name } from '../../Helpers/request';

const Testimonial = () => {
    const history = useHistory();
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="rcs_testimonials t_testimonila_section">
                <Container className='tokyo_custome_container'>
                 <Row>
                    <Col sm={12} md={12} lg={12}>
                    <div class="title-box2 text-center main_heading"><h2 class="title24 dark play-font ">WHAT PEOPLE SAY</h2></div>
                    </Col>
                 </Row>
                 <Row className='testimo-slider2'>
                    <Col sm={12} md={12} lg={12}>
                    <div class="testimonial-icon1">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                                <Slider className="rcs_testimonial_inner overflow-hidden" {...settings2}>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=galaxydiamonds+in+uk&rlz=1C1CHZN_enIN1077IN1077&oq=galaxydiamonds+in+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQIRgKGKABMgkIAhAhGAoYoAEyBwgDECEYjwLSAQg0NzA5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x48761b4db8098683:0xd89c152950d5cb0c,1,,,,", "_blank")} to='#'>
                                <img class="google_rev_img" src={google_icon} /> </NavLink>
                            <p>We came across Galaxy Diamonds last as we had been just browsing from shop to shop to get an idea only. We were seen by Imti at Galaxy Diamonds who was lovely, patient, helpful and willing to show me different styles of rings as I did not know which style I wanted.
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Maria Cristina Mone</h4> </div> 
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=galaxydiamonds+in+uk&rlz=1C1CHZN_enIN1077IN1077&oq=galaxydiamonds+in+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQIRgKGKABMgkIAhAhGAoYoAEyBwgDECEYjwLSAQg0NzA5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x48761b4db8098683:0xd89c152950d5cb0c,1,,,,", "_blank")} to='#'>
                                <img class="google_rev_img" src={google_icon} /> </NavLink>
                            <p>My fiancé walked into the shop with a faint idea of what i would like and somehow the team created THE most perfect engagement ring i could ever dream of! And then they did it again when we went to them for our wedding bands.</p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Elissa Abi Raad</h4> </div>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=galaxydiamonds+in+uk&rlz=1C1CHZN_enIN1077IN1077&oq=galaxydiamonds+in+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQIRgKGKABMgkIAhAhGAoYoAEyBwgDECEYjwLSAQg0NzA5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x48761b4db8098683:0xd89c152950d5cb0c,1,,,,", "_blank")} to='#'>
                                <img class="google_rev_img" src={google_icon} /> </NavLink>
                            <p>I went here for a fully customised ring.. with 5 6 pictures and ideas combined into one ring
It came out exactly as how i had imagined. I am shocked at the precision.</p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Sanam Alam</h4> </div>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=galaxydiamonds+in+uk&rlz=1C1CHZN_enIN1077IN1077&oq=galaxydiamonds+in+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQIRgKGKABMgkIAhAhGAoYoAEyBwgDECEYjwLSAQg0NzA5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x48761b4db8098683:0xd89c152950d5cb0c,1,,,,", "_blank")} to='#'>
                                <img class="google_rev_img" src={google_icon} /> </NavLink>
                            <p>Had a ring remodelled here recently and was blown away by the level of customer service and execution. Toni explained all of the different options and price points very clearly and stayed in contact throughout the process, </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>talia gibson</h4> </div>
                        </div>
                        {/* <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("#", "_blank")} to='#'>
                            <img class="google_rev_img" src={yelp_icon} /> </NavLink>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Lorem Ipsum</h4> </div>
                        </div> */}
                        {/* <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=u-niHA_hXScxw3NZ1H78iA&rh_type=phrase&rh_ident=asaad", "_blank")} to='#'>
                                <img class="google_rev_img" src={ivouch_icon} /> </NavLink>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Lorem Ipsum</h4> </div>
                        </div> */}
                        {/* <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=u-niHA_hXScxw3NZ1H78iA&rh_type=phrase&rh_ident=asaad", "_blank")} to='#'>
                                <img class="google_rev_img" src={ivouch_icon} /> </NavLink>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <div className="rcs_testi_user"> <h4>Lorem Ipsum</h4> </div>
                        </div> */}
                    </Slider>
                    </Col>
                 
                 </Row>
                </Container>
            </div>
        </>
    )
}

export default Testimonial;