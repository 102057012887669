import React from 'react'
import {Container, Row} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import grid2 from './images/grid2-12x.jpg'
import grid22 from './images/grid2-22x.jpg'
import grid3 from './images/nackles_image.jpg'

const DiamondNecklaces = () => {
  return (
    <div className='t_diamond_necklaces_Section'>
      <section className="discover_all">
			<Container className="tokyo_custome_container">
                <Row>

               
				<div className="discover_box">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-6 col-12">
							<div className="discover_boximg">
								<img src={grid2} className="img-responsive" alt="img"/>
							</div>
						</div>

						<div className="col-lg-6 col-md-12 col-sm-6 col-12 pd0">
							<div className="discover_boxcontent">
								<div className="discover_boxinner">
									<h2>4C's of Diamond</h2>
								<div className="discover_btn">								
									<NavLink className="main_button" to="/education/loose-diamonds-education#section-1">Learn More</NavLink>
								</div>

								</div>					

								<div className="discover_boximg2">

									<img src={grid22} className="img-responsive" alt="img"/>
								</div>
							</div>
						</div>
					</div>
				</div>
                </Row>
			</Container>
		</section>
        <section className="discover_all2">
			<Container className="tokyo_custome_container">
				<div className="discover_box2">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-6 col-12 pd0">
							<div className="discover_boximg21" 
							>
								<img src={grid3} className="img-responsive" alt="img"/>

							</div>
						</div>

						<div className="col-lg-6 col-md-12 col-sm-6 col-12 discover_boxcontent2"
						 >
							<div className="discover_boxcontentin2">
										<div className="beauty_content">
											<h2>Necklaces</h2>
											<p>Find the perfect length for every occasion with classic pearl strands, diamond elegance, colorful gemstones and more!</p>
												<div className="discover_btn">
													
													<NavLink className="main_button" to="/jewelry/necklaces">Explore the Collection</NavLink>
												</div>
										</div>		

								
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
    </div>
  )
}

export default DiamondNecklaces
