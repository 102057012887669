import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

import engImg from "../../../Containers/Front/tokyo/images/collection/engagement_rings.jpg";
import weddingImg from "../../../Containers/Front/tokyo/images/collection/wedding_rings.jpg";
import braceletsImg from "../../../Containers/Front/tokyo/images/collection/bracelets.jpg";
import necklaseImg from "../../../Containers/Front/tokyo/images/collection/necklase.jpg";
import earringsImg from "../../../Containers/Front/tokyo/images/collection/earrings.jpg";

import Slider from "react-slick";
import { isMobile } from 'react-device-detect'
const BestCollections = () => {
  var settingsBestCollection = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const bestCollectionData = [
    {
      img: engImg,
      name: "Engagement Rings",
      url: "/ringsettings",
    },
    {
      img: weddingImg,
      name: "Wedding Rings",
      url: "/jewelry/wedding-rings",
    },
    {
      img: earringsImg,
      name: "Earrings",
      url: "/jewelry/earrings",
    },
    {
      img: braceletsImg,
      name: "Bracelets",
      url: "/jewelry/bracelets",
    },
    {
      img: necklaseImg,
      name: "Necklaces",
      url: "/jewelry/necklaces",
    },

  ];


  return (
    <div className='t_best_collection_Section product-bestsale-jewelry hidden-xs'>
      <Container className="tokyo_custome_container">

        {isMobile ?
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Slider {...settingsBestCollection} className='g_best_collection_slider'>
                {bestCollectionData.map((val) => (
                  <div id="banner0" className="banners explore_new">
                    <div class="cateli_main" 
                    >
                      <NavLink to={val.url}>
                        <div class="catimg_main">
                          <img src={val.img} />
                        </div>
                      </NavLink>
                      <p> {val.name}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
          :
          <Row>
            <Col sm={12} md={12} lg={12} className='p-0'>
              <div class="title-box2 text-center main_heading">
                <h2 class="title30 dark play-font">Best Collections</h2>

              </div>

              <div class="filter-catelog">

                <ul class="best_seller_ul">


                  <li class="cateli_main">
                    <NavLink to="/ringsettings">
                      <div class="catimg_main">
                        <img src={engImg} />
                      </div>
                    </NavLink>
                    <p> Engagement Rings</p>
                  </li>

                  <li class="cateli_main">
                    <NavLink to="/jewelry/wedding-rings">
                      <div class="catimg_main">
                        <img src={weddingImg}/>
                      </div>
                    </NavLink>
                    <p class="white"> Wedding Rings</p>

                  </li>

                  <li class="cateli_main">
                    <NavLink to="/jewelry/earrings">
                      <div class="catimg_main">
                        <img src={earringsImg} />
                      </div>
                    </NavLink>
                    <p> Earrings</p>
                  </li>

                  <li class="cateli_main">
                    <NavLink to="/jewelry/bracelets">
                      <div class="catimg_main">
                        <img src={braceletsImg}/>
                      </div>
                    </NavLink>
                    <p class="white"> Bracelets</p>
                  </li>

                  <li class="cateli_main">
                    <NavLink to="/jewelry/necklaces">
                      <div class="catimg_main">
                        <img src={necklaseImg} />
                      </div>
                    </NavLink>
                    <p> Necklaces </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        }



      </Container>
    </div>
  )
}

export default BestCollections
