import React from 'react'
import ScrollToTop from "react-scroll-to-top";

const ScrollToTopButton = () => {
  return (
    <div className='t_scroll_to_top_button'>
       <ScrollToTop smooth ><i class="fa fa-angle-up"></i></ScrollToTop>
    </div>
  )
}

export default ScrollToTopButton
