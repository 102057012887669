import React from 'react'
import round from '../../../Assets/images/diamond-shape/Diamond_Round.svg'
import princess from '../../../Assets/images/diamond-shape/princess.svg'
import cushion from '../../../Assets/images/diamond-shape/cushion.svg'
import emerald from '../../../Assets/images/diamond-shape/emerald.svg'
import oval from '../../../Assets/images/diamond-shape/oval.svg'
import radiant from '../../../Assets/images/diamond-shape/radiant.svg'
import asscher from '../../../Assets/images/diamond-shape/asscher.svg'
import marquise from '../../../Assets/images/diamond-shape/marquise.svg'
import heart from '../../../Assets/images/diamond-shape/heart.svg'
import pear from '../../../Assets/images/diamond-shape/pear.svg'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import { Container, Row, Col, Image} from 'react-bootstrap'
import Slider from "react-slick";
import { isMobile} from 'react-device-detect'


const ShopDiamondByShap = () => {
    const shapesData = [
        {
           shapName:"round",
            shapImage:round,
            shapUrl:"/diamonds/shape=Round",
        },
        {
            shapName:"princess",
             shapImage:princess,
             shapUrl:"/diamonds/shape=Princess",
         },
         {
            shapName:"cushion",
             shapImage:cushion,
             shapUrl:"/diamonds/shape=Cushion",
         },
         {
            shapName:"emerald",
             shapImage:emerald,
             shapUrl:"/diamonds/shape=Emerald",
         },
         {
            shapName:"oval",
             shapImage:oval,
             shapUrl:"/diamonds/shape=Oval",
         },
         {
            shapName:"radiant",
             shapImage:radiant,
             shapUrl:"/diamonds/shape=Radiant",
         },
         {
            shapName:"asscher",
             shapImage: asscher,
             shapUrl:"/diamonds/shape=Asscher",
         },
         {
            shapName:"marquise",
             shapImage: marquise,
             shapUrl:"/diamonds/shape=Marquise",
         },
         {
            shapName:"heart",
             shapImage: heart,
             shapUrl:"/diamonds/shape=Heart",
         },
         {
            shapName:"pear",
             shapImage: pear,
             shapUrl:"/diamonds/shape=Pear",
         },
    ]

    var settingsShopByShap = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
  return (
    <div className='t_shop_by_shap_Section'>
      <Container className='tokyo_custome_container'>
        <Row>
            <Col sm={12} md={12} lg={12}>
            <div class="title-box2 text-center main_heading">
					<h2 class="title24 dark play-font ">Shop Diamonds By Shape</h2>
					
				</div>
            </Col>
        </Row>
      

       {isMobile?

<Row>
        <Col sm={12} md={12} lg={12} className="p-0">
      <Slider {...settingsShopByShap}>
        {shapesData.map((val) => (
       <div>
       <NavLink to={val.shapUrl} className="t_shap_imgg_boxx_With_name">
           <Image src={val.shapImage} alt={val.shapName + "Image"}/>
           <h3>{val.shapName}</h3>
       </NavLink>
      </div>
        ))}
      </Slider>
    </Col>
        </Row>
        : 
        <Row>
        <Col sm={12} md={12} lg={12}>
<div>
<ul>
   {shapesData.map((val)=>(
<li>
<NavLink to={val.shapUrl} className="t_shap_imgg_boxx_With_name">
 <Image src={val.shapImage} alt={val.shapName + "Image"}/>
 <h3>{val.shapName}</h3>
</NavLink>
</li>
   ))}
</ul>
</div>
        </Col>
    </Row>
       
       } 
      </Container>
    </div>
  )
}

export default ShopDiamondByShap
