import React from 'react';
// import Container from '../ui/Container';

import Heading from '../ui/Heading';
import { Container } from 'react-bootstrap';

const Insta = () => {
    return (
        <section className='insta-section section-space pb-0'>
            <Container className='tokyo_custome_container'>
            <div className="title-box2 text-center main_heading ">
            <h2 className="title30 play-font mb-0">Get a Tour of our Social Media</h2>
            <div id="social-tabs">
                <div className="tab-content clearfix">
                    <div className="tab-pane active" id="insta-section">
                        <h5 className="subtitle text-center mb-5">Follow <a href="https://www.instagram.com/galaxy_diamonds_ltd/" target="_blank" className='text-dark'> <strong>@GalaxyDiamonds</strong></a> on Instagram to get inspired</h5>
                        <iframe src="https://instagram.demobw.live/galaxy-diamonds/" frameborder="0" width="100%" class="insta-iframe"></iframe>
                    </div>
                </div>
            </div>
        </div>
                {/* <Heading>
                    <h2>Social Media</h2>
                    <p>Follow us on Instagram <a href='https://www.instagram.com/belgium_webnet/' className='text-dark' target='_blank'><strong>@belgium_webnet</strong></a></p>
                </Heading> */}
              
            </Container>
        </section>
    )
}

export default Insta;