import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const LooseDiamond = () => {
  return (
    <div className='t_loose_diamond_Section'>
 
      <div className="natursection">
    <Container className="tokyo_custome_container ">
        <div className="row">
            <div className="col-md-12">
                <div className="naturessection-box" 
                // data-aos="fade-up" data-aos-duration="3000"
                >
                    <h2> Loose Diamonds </h2>
                    {/* <!-- <b>Lorem Ipsum is simply dummy text</b> --> */}
                    <p>All diamonds vary in size, color, clarity, cut, and carat weight. We only deal in certified and graded loose diamonds of the highest quality.</p>
                    <NavLink to="/diamonds" className="main_button">Discover More</NavLink>
                </div>
            </div>
        </div>
    </Container>
</div>

    </div>
  )
}

export default LooseDiamond
